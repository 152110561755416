<template>
  <div class="page bg-white" id="reorganize">
    <el-row :gutter="20">
      <el-col :span="6">
        <div class="lump">
          <div class="catalogueBox">
            <div class="flex_l_c">
              <div class="f_w">展览：</div>
              <el-input class="flex1" maxlength="66" v-model="catalogueSrh" clearable placeholder="请输入展览名称"
                size="small"></el-input>
            </div>
            <el-table :data="catalogueList" ref="catalogueTable" size="small" height="calc(100vh - 268px)" class="table"
              highlight-current-row @current-change="handleCurrentChange">
              <el-table-column prop="exhibitionName" label="展览" show-overflow-tooltip />
            </el-table>
            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
              :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background layout="prev, pager, next">
            </el-pagination>
          </div>
        </div>
      </el-col>
      <el-col :span="18">
        <div class="lump">
          <div class="flex_b_c headBox">
            <div class="flex_l_c">
              <el-input class="flex1 m_r1" maxlength="66" v-model="fileSrh" clearable placeholder="请输入文件名称"
                size="small"></el-input>
              <el-button icon="el-icon-search" type="primary" size="small" @click="searchFileList">查询</el-button>
            </div>
            <div>
              <!--                            <el-button icon="el-icon-printer" size="small" plain>打印报表</el-button>-->
              <!-- <el-button icon="el-icon-document" size="small" plain @click="getLog">日志</el-button> -->
            </div>
          </div>
          <div class="catalogueBox">
            <div class="flex_b_c">
              <div class="f_w">文物清单</div>
              <div>
                <el-dropdown class="m_l1">
                  <el-button type="primary" size="small">
                    智能操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="download()"
                      :disabled="dataListSelections.length <= 0">下载</el-dropdown-item>
                    <el-dropdown-item @click.native="back()"
                      :disabled="dataListSelections.length <= 0">退回</el-dropdown-item>
                    <el-dropdown-item @click.native="permissions(null)"
                      :disabled="dataListSelections.length <= 0">权限调整</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button-group class="m_l1">
                  <el-button size="small" plain icon="el-icon-refresh" @click="getFileList"></el-button>
                  <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                </el-button-group>
              </div>
            </div>
            <el-table :data="fileList" size="small" @selection-change="selectionChangeHandle" v-loading="loading"
              height="calc(100vh - 320px)" class="table" ref="tableBox">
              <el-table-column type="selection" width="50" fixed />
              <el-table-column v-for="(item, index) in config" :key="item.id" :prop="item.fieldEnname"
                :label="item.recordName" :show-overflow-tooltip="item.fieldEnname != 'url'" v-if="item.onShow == 0">
                <template slot-scope="scope">
                  <el-image v-if="item.fieldEnname == 'url'" style="width: 60px; height: 60px" :src="scope.row.accessPath"
                    fit="contain"></el-image>
                  <div v-if="item.fieldEnname != 'url' && scope.row[item.fieldEnname]">{{ scope.row[item.fieldEnname] }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="200">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" plain @click="view(scope.row)">详情</el-button>
                  <el-button size="mini" type="primary" plain @click="download(scope.row)">下载</el-button>
                  <!--                                    <el-button size="mini" type="primary" plain @click="print(scope.row)" >打印</el-button>-->
                  <el-button size="mini" type="primary" plain @click="permissions2(scope.row.id)">权限调整</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="sizeChangeHandle2" @current-change="currentChangeHandle2" :current-page="pageNo2"
              :page-sizes="[10, 20, 50, 100]" :page-size="pageSize2" :total="total2" background
              layout="total, prev, pager, next, sizes">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--列表设置拖拽排序弹窗-->
    <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
    <!-- 详情 -->
    <FileDetails ref="fileDetails" @refreshDataList="getFileList"></FileDetails>
    <!--    日志    -->
    <Log ref="reorganizeLog"></Log>

    <User ref="user" @refreshDataList="getFileList"></User>
  </div>
</template>

<script>
import DraggablePop from '@/components/draggable/draggablePop' // 导入排序弹窗
import FileDetails from '../../dataCollection/cultural/fileDetails' // 详情
import Log from "../../dataCollection/log"; // 上传日志
import User from "../user"; // 用户

export default {
  components: { DraggablePop, FileDetails, Log, User },
  data() {
    return {
      // 展览
      catalogueSrh: '',
      catalogueList: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      // 文物清单
      fileSrh: '',
      exhibitionId: "",
      fileList: [],
      loading: false,
      pageNo2: 1,
      pageSize2: 10,
      total2: 0,
      moduleId: 3,   // 当前请求拖拽排序数据id
      setShow: false, // 是否显示列表设置拖拽排序弹窗
      dataListSelections: [],
      log: {
        operModular: "4",//模块
        operType: "11",//类型
        operTerm: "",//内容
        operSystem: "1",//结果
      },
      config: []
    }
  },
  watch: {
    // 实时输入查询目录
    catalogueSrh() {
      this.searchCatalogue();
    },
  },
  mounted() {
    this.getCatalogue();
    // this.getFileList();
    this.querySysOriginalDescriptionAll();

  },
  methods: {
    searchCatalogue() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.total = 0;
      this.getCatalogue();
      this.fileList = [];
    },
    querySysOriginalDescriptionAll() {
      let that = this;
      this.$axios(this.api.catalogue.querySysOriginalDescriptionAll, {
        flag: 3,
        onDetails: 1
      }, 'get').then(data => {
        if (data) {
          this.config = data
          this.config.forEach(function (v) {
            v.fieldEnname = that.toHump(v.fieldEnname)
            if (v.fieldEnname == 'updateId') {
              v.fieldEnname = 'updateName'
            }
            if (v.fieldEnname == 'createId') {
              v.fieldEnname = 'createName'
            }
            // that.$set(that.inputForm,v.fieldEnname,"")
          })
          console.log("配置：", this.config);
        }
      })
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    // 查询展览
    getCatalogue() {
      this.$axios(this.api.common.queryExhibition, {
        'name': this.catalogueSrh,
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
      }, 'get').then(data => {
        if (data && data.status) {
          this.catalogueList = data.data.records
          this.total = parseInt(data.data.total)
        }
      })
    },
    // 点击展览行
    handleCurrentChange(val) {
      this.$refs.catalogueTable.setCurrentRow(val);
      this.exhibitionId = val.id
      this.getFileList();
    },
    searchFileList() {
      this.pageNo2 = 1;
      this.pageSize2 = 10;
      this.total2 = 0;
      this.getFileList();
    },
    // 查询文物清单
    getFileList() {
      if (this.exhibitionId) {
        this.loading = true
        this.$axios(this.api.common.culturalRelicsList, {
          'exhibitionId': this.exhibitionId,
          "fileName": this.fileSrh,
          "archive": 1,
          'pageNo': this.pageNo2,
          'pageSize': this.pageSize2,
          'flag': 3,
          'onDetails': 1,
        }, 'get').then(data => {
          if (data && data.status) {
            this.fileList = data.data.records
            this.total2 = parseInt(data.data.total)
            this.loading = false
            this.$refs.tableBox.doLayout();
          }
        })
      }

    },
    // 文物清单表格多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 详情
    view(row) {
      this.$refs.fileDetails.init("view", row, this.exhibitionId, "management")
    },
    // 下载
    download(row) {
      let rows = [];
      if (row) {
        rows = [row]
      } else {
        rows = this.dataListSelections
      }
      console.log(rows);
      let that = this;
      rows.forEach(function (v) {
        that.log.operTerm = v.culturalName
        const a = document.createElement('a')
        //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
        const url = v.accessPath;  // 完整的url则直接使用
        // 这里是将url转成blob地址，
        fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob)
          console.log(a.href)
          a.download = v.culturalName // 下载文件的名字
          // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
          document.body.appendChild(a)
          a.click()
          that.log.operSystem = '0'
          // that.$axios(that.api.common.logSave, that.log, 'post')
        })
      })
    },
    //退回
    back() {
      this.$confirm(`确定退回所选项吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.log.operType = "11"
        let ids = this.dataListSelections.map(item => {
          return item.id
        }).join(',')
        this.$axios(this.api.common.culturalRelicsBack, {
          'ids': ids,
        }, 'put').then(data => {
          if (data && data.status) {
            this.log.operSystem = '0'
            this.$message.success("已退回")
            // this.getFileList();
          } else {
            this.log.operSystem = '1'
          }
          this.logSave(this.dataListSelections);
        })
      })
    },
    permissions() {
      this.$refs.user.init(this.dataListSelections, 0)
    },
    permissions2(id) {
      this.$refs.user.init(0, id)
    },
    //新增日志
    logSave(dataListSelections) {
      let that = this;
      let batchList = []
      dataListSelections.forEach(function (v) {
        let log = JSON.parse(JSON.stringify(that.log))
        log.operTerm = v.culturalName
        batchList.push(log)
      })
      // this.$axios(this.api.common.batchInsertLogin, batchList, 'post').then(data => {
        this.getFileList()
      // })
    },
    //查看日志
    getLog() {
      this.$refs.reorganizeLog.init('4')
    },
    //打印
    print(row) {

    },
    // 设置
    setTb() {
      this.setShow = true;
    },
    // 接收子组件（排序弹窗）传过来的值
    getTbList(data, state) {
      this.config = data;
      this.getFileList();
      this.setShow = state;
    },
    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getCatalogue();
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getCatalogue();
    },
    // 文物清单每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getFileList();
    },
    // 文物清单当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getFileList();
    },
  }
}
</script>

<style scoped></style>
